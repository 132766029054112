// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require */

const find = require('lodash/find');

module.exports = function screenSharingFactory(deps = {}) {
  const chromeExtensionHelper = deps.chromeExtensionHelper || require('./chrome_extension_helper.js')();
  const electronExtensionHelper = deps.electronExtensionHelper || require('./electron_extension_helper.js')();
  const Errors = deps.Errors || require('../Errors.js');
  const firefoxExtensionHelper = deps.firefoxExtensionHelper || require('./firefox_extension_helper.js');
  const getDisplayMediaExtensionHelper = deps.getDisplayMediaExtensionHelper || require('./getDisplayMedia_extension_helper.js');
  const otError = deps.otError || require('../../helpers/otError.js')();
  const OTHelpers = deps.OTHelpers || require('../../common-js-helpers/OTHelpers.js');

  const screenSharing = {};

  screenSharing.extensionByKind = {};
  screenSharing.extensionClasses = {};

  screenSharing.registerExtensionHelper = (kind, helper) => {
    screenSharing.extensionClasses[kind] = helper;
    if (helper.autoRegisters && helper.isSupportedInThisBrowser) {
      screenSharing.registerExtension(kind);
    }
  };

  /**
   * Register an extension for screen-sharing support in an older version of Chrome or Opera.
   * <p>
   * <b>Note:</b> A screen-sharing extension is not required in Chrome 72+ or Opera 59+.
   * The end user is prompted to grant access to the screen, just as they would be when granting
   * access to a camera. You only need a screen-sharing extension for older versions
   * of Opera and Chrome.
   * <p>
   * Use the <code>OT.checkScreenSharingCapability()</code> method to check if an extension is
   * required, registered, and installed.
   * <p>
   * The OpenTok
   * <a href="https://github.com/opentok/screensharing-extensions">screensharing-extensions</a>
   * repo includes code for creating an extension for screen-sharing support.
   *
   * @param {String} kind Set this parameter to <code>"chrome"</code>. Currently, you can only
   * register a screen-sharing extension for older versions of Chrome and Opera.
   *
   * @param {String} id The ID for your screen-sharing extension. You can find this ID at
   * chrome://extensions.
   *
   * @param {Number} version The version of the screen-sharing extension from the
   * <a href="https://github.com/opentok/screensharing-extensions">screensharing-extensions</a> repo.
   * Set this if you are using version 2 or later. For example, if you are using version 2, set this
   * to 2. With version 2, the client can use the extension immediately after installing it, without
   * reloading the page.
   *
   * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
   * @see <a href="OT.html#checkScreenSharingCapability">OT.checkScreenSharingCapability()</a>
   * @method OT.registerScreenSharingExtension
   * @memberof OT
   */

  screenSharing.registerExtension = (kind, ...initArgs) => {
    if (screenSharing.extensionClasses[kind] == null) {
      throw new Error('Unsupported kind passed to OT.registerScreenSharingExtension');
    }

    const x = screenSharing
      .extensionClasses[kind]
      .register(...initArgs);

    screenSharing.extensionByKind[kind] = x;
  };

  const screenSharingPickHelper = () => {
    const foundClass = find(
      Object.keys(screenSharing.extensionClasses).sort(
        // Try "electron" first.  Reason being, even though `getDisplayMedia`
        // is exposed in electron, developers must use the electron's
        // `desktopCapturer` instead.
        cls =>
          (cls === 'electron' ? -1 : 1)
      ),
      cls => screenSharing.extensionClasses[cls].isSupportedInThisBrowser
    );

    if (foundClass === undefined) {
      return {};
    }

    return {
      name: foundClass,
      proto: screenSharing.extensionClasses[foundClass],
      instance: screenSharing.extensionByKind[foundClass],
    };
  };

  screenSharing.pickHelper = () => screenSharingPickHelper();

  /**
   * Checks for support for publishing screen-sharing streams on the client browser. The object
   * passed to the callback function defines whether screen sharing is supported, as well as
   * which screen-sharing sources (application, screen, or window) are supported. It also indicates
   * whether an extension is required, installed, and registered (if needed).
   * <p>
   * <pre>
   * OT.checkScreenSharingCapability(function(response) {
   *   if (!response.supported || response.extensionRegistered === false) {
   *     // This browser does not support screen sharing
   *   } else if (response.extensionInstalled === false) {
   *     // Prompt to install the extension
   *   } else {
   *     // Screen sharing is available.
   *   }
   * });
   * </pre>
   * <p>
   * Chrome 72+, Firefox 52+, Safari 13+, Edge 79+, and Opera 59+ have screen-sharing
   * support built-in, with no extension required. (Note that support for the OpenTok
   * plugin for Internet Explorer is removed in OpenTok 2.17.) Screen sharing is
   * <i>not</i> supported in mobile browsers. In Electron, screen sharing is supported if
   * the <code>webPreferences.contextIsolation</code> option of the Electron BrowserWindow is
   * set to <code>false</code> or if the app uses a preload script to access the desktop capturer.
   * (The default value of <code>webPreferences.contextIsolation</code> option is <code>true</code>
   * in Electron 12 and <code>false</code> in previous versions). To publish a screen-sharing video
   * in older versions of Chrome or Opera, the client adds an extension that enables publishing a
   * screen-sharing video stream on your domain. The OpenTok
   * <a href="https://github.com/opentok/screensharing-extensions">screensharing-extensions</a>
   * sample includes code for creating an extension for screen-sharing support in
   * older versions of Chrome and Opera.
   * <p>
   * For more information, see the <a href="https://tokbox.com/developer/guides/screen-sharing/js/">
   * OpenTok Screen Sharing</a> developer guide.
   *
   * @param {function} callback The callback invoked with the support options object passed as
   * the parameter. This object has the following properties that indicate support for publishing
   * screen-sharing streams in the client:
   * <p>
   * <ul>
   *   <li>
   *     <code>extensionInstalled</code> (Boolean) &mdash;  In older versions of Chrome and Opera,
   *     this is set to <code>true</code> if the extension is installed and registered.
   *     In Chrome 72+, Firefox 52+, Safari 13+, Opera 59+, Edge 79+, and Internet Explorer,
   *     this property is undefined.
   *   </li>
   *   <li>
   *     <code>supported</code> (Boolean) &mdash; Set to <code>true</code> if screen sharing
   *     is supported in the browser. Check the <code>extensionRequired</code> property
   *     to see if the browser requires an extension for screen sharing.
   *   </li>
   *   <li>
   *     <code>supportedSources</code> (Object) &mdash; An object with the following properties:
   *     <code>application</code>, <code>browser</code>, <code>screen</code>, and
   *     <code>window</code>. Each property is a Boolean value indicating support.
   *     In Firefox, you can set a specify the type of screen-sharing source
   *     by setting the <code>videoSource</code> property of the options passed into the
   *     <a href="#initPublisher">OT.initPublisher()</a> method. Set the property to
   *     <code>"application"</code>, <code>"screen"</code>, or <code>"window"</code>.
   *     In other browsers, setting the <code>videoSource</code> property to any of these values
   *     results in a prompt that asks the user to determine the screen-sharing source.
   *     In Firefox, Chrome, and Opera, <code>browser</code>, <code>screen</code>, and
   *     <code>window</code> are set to <code>true</code>. In Chrome, only <code>screen</code> and
   *     <code>window</code> are set to <code>true</code>. In Electron, screen sharing captures
   *     the entire screen, without prompting the user for permission.
   *   </li>
   * </ul>
   * <p> The options parameter also includes the following properties, which apply to screen-sharing
   * support in older versions of Chrome and Opera (in all other browsers these properties are
   * undefined):
   * <ul>
   *   <li>
   *     <code>extensionRequired</code> (String) &mdash; Set to <code>"chrome"</code>
   *     in older versions of Chrome and Opera, which require a screen-sharing extension
   *     to be installed. This property is undefined in other browsers.
   *   </li>
   *   <li>
   *     <code>extensionRegistered</code> (Boolean) &mdash; In older versions of Chrome and Opera,
   *     this property is set to <code>true</code> if a screen-sharing extension is registered;
   *     otherwise it is set to <code>false</code>. In other browsers (which do not require
   *     an extension), this property is undefined. Use the
   *     <code>OT.registerScreenSharingExtension()</code> method to register a screen-sharing
   *     extension in older versions of Chrome or Opera.
   *   </li>
   * </ul>
   *
   * @see <a href="OT.html#initPublisher">OT.initPublisher()</a>
   * @see <a href="OT.html#registerScreenSharingExtension">OT.registerScreenSharingExtension()</a>
   * @method OT.checkScreenSharingCapability
   * @memberof OT
   */
  screenSharing.checkCapability = (callback) => {
    const response = {
      supported: false,
      extensionRequired: undefined,
      extensionRegistered: undefined,
      extensionInstalled: undefined,
      supportedSources: {},
    };

    // find a supported browser

    const helper = screenSharingPickHelper();

    if (helper.name === undefined) {
      setTimeout(callback.bind(null, response));
      return;
    }

    response.supported = true;
    response.extensionRequired = helper.proto.extensionRequired ? helper.name : undefined;

    response.supportedSources = {
      screen: helper.proto.sources.screen,
      application: helper.proto.sources.application,
      window: helper.proto.sources.window,
      browser: helper.proto.sources.browser,
    };

    if (!helper.instance) {
      response.extensionRegistered = false;
      if (response.extensionRequired) {
        response.extensionInstalled = false;
      }
      setTimeout(callback.bind(null, response));
      return;
    }

    response.extensionRegistered = response.extensionRequired ? true : undefined;
    helper.instance.isInstalled((installed) => {
      response.extensionInstalled = (response.extensionRequired || OTHelpers.env.name === 'Firefox') ?
        installed : undefined;
      callback(response);
    });
  };

  screenSharing.registerExtensionHelper('electron', electronExtensionHelper);
  screenSharing.registerExtensionHelper('getDisplayMedia', getDisplayMediaExtensionHelper);
  screenSharing.registerExtensionHelper('chrome', chromeExtensionHelper);
  screenSharing.registerExtensionHelper('firefox', firefoxExtensionHelper);

  const noop = () => {};

  screenSharing.getConstraints = (opt) => {
    const {
      onAccessDialogOpened = noop,
      onAccessDialogClosed = noop,
      videoSource,
      constraints,
    } = opt;

    return new Promise((resolve, reject) => {
      screenSharing.checkCapability((response) => {
        if (!response.supported) {
          let errorMessage = 'Screen Sharing is not supported in this browser';

          if (OTHelpers.env.isElectron) {
            errorMessage = 'Set the webPreferences.contextIsolation option of the Electron BrowserWindow'
            + ' to false or use a preload script to access the desktop capturer.'
            + ' For more information, see https://tokbox.com/developer/guides/screen-sharing/js/.';
          } else if (OTHelpers.env.name === 'Chrome' && OTHelpers.env.version > 73 && global.location.protocol !== 'https:') {
            errorMessage = 'https:// is required for screen sharing';
          }

          reject(otError(
            Errors.SCREEN_SHARING_NOT_SUPPORTED,
            new Error(errorMessage)
          ));
        } else if (response.extensionRegistered === false) {
          reject(otError(
            Errors.SCREEN_SHARING_EXTENSION_NOT_REGISTERED,
            new Error('Screen Sharing support in this browser requires an extension, but ' +
              'one has not been registered.')
          ));
        } else if (response.extensionRequired && response.extensionInstalled === false) {
          reject(otError(
            Errors.SCREEN_SHARING_EXTENSION_NOT_INSTALLED,
            new Error('Screen Sharing support in this browser requires an extension, but ' +
              'the extension is not installed.')
          ));
        } else {
          const helper = screenSharing.pickHelper();

          if (helper.proto.getConstraintsShowsPermissionUI) {
            onAccessDialogOpened();
          }

          helper.instance.getConstraints(videoSource, constraints,
            (err, helperConstraints) => {
              if (helper.proto.getConstraintsShowsPermissionUI) {
                onAccessDialogClosed();
              }
              if (err) {
                reject(err);
              } else {
                resolve(helperConstraints);
              }
            });
        }
      });
    });
  };

  return screenSharing;
};
